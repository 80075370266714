import { useIonRouter } from '@ionic/react';
import React, { useEffect, useLayoutEffect, useState } from 'react';


import { Box, Boxed, ButtonLayout, ButtonLink, ButtonPrimary, ButtonSecondary, Callout, Checkbox, DataCard, DateField, DecimalField, dialog, DisplayDataCard, EmailField, Form, Grid, GridLayout, IconCheckedRegular, IconComputerRegular, IconDocumentOtherRegular, IconDocumentsRegular, IconIdCardFilled, IconInformationUserLight, IconInformationUserRegular, IconMobileDeviceRegular, IconPenRegular, IconProcessLoadingRegular, IconUserAccountRegular, Inline, IntegerField, LoadingBar, PasswordField, RadioButton, RadioGroup, ResponsiveLayout, Select, SkeletonLine, SkeletonRectangle, skinVars, Stack, Stepper, Switch, Text1, Text2, Text3, Text4, TextField, TextLink, Tooltip, useScreenSize } from '@telefonica/mistica';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useAlert } from '../../../components/alerts/Alert';
import LayoutPublic from '../../../components/layout/LayoutPublic';
import { useProductActions } from '../../../components/operate/ProductActions';
import { CheckIdCardResponse } from '../../../entities/clients/check-idcard-response';
import { UserInvalidException } from '../../../entities/clients/user-invalid-exception';
import { ListOption } from '../../../entities/commons/list-option.model';
import { Product } from '../../../entities/products/product';
import { activateAccount, checkDataRegister, checkIdCard, downloadAttachmentSignedDocument, downloadContractDocument, downloadSignedDocument, getMasterDatas, getProvinceCity, login, register, setSignContract, setStateSignaturitWithoutToken } from '../../../utils/apiclient';
import { getUrlNavigation } from '../../../utils/navigation';
import { addUser, getDeviceId, getNameRegister, getProductToContract, removeContinueToContract, removeProductToContract, removeUser, selectRoleUser, setContinueToContract, setProductToContract } from '../../../utils/storage';
import { formatContract, getPlatform, getPlatformDetail, isBot, isNativeApp, scrollToTop, trackError, trackEvent } from '../../../utils/utils';
import LoginPage from '../login/LoginPage';
import './RegisterPage.scss';

const RegisterPage: React.FC = (prop) => {
  const { contract } = useProductActions()
  const { showAlert } = useAlert()
  const screenSize = useScreenSize();
  const intl = useIntl();
  const router = useIonRouter();

  const params = useParams() as any;

  const [initNameRegister, setInitNameRegister] = React.useState(getNameRegister());

  const [genderOptions, setGenderOptions] = React.useState([] as ListOption[])
  const [countryOptions, setCountryOptions] = React.useState([] as ListOption[])
  const [provinceOptions, setProvinceOptions] = React.useState([] as ListOption[])
  const [viaOptions, setViaOptions] = React.useState([] as ListOption[])
  const [employmentSituationOptions, setEmploymentSituationOptions] = React.useState([] as ListOption[])
  const [activitySectorOptions, setActivitySectorOptions] = React.useState([] as ListOption[])
  const [activitySectorOptionsInit, setActivitySectorOptionsInit] = React.useState([] as ListOption[])


  //Signature
  const [urlSignature, setUrlSignature]= React.useState(null as string|null);
  const [signatureId, setSignatureId]= React.useState(null as string|null);
  const [documentId, setDocumentId]= React.useState(null as string|null);
  const [isState, setIsState]= React.useState(false);

  const [validateClientForm, setValidateClientForm] = React.useState(null as CheckIdCardResponse|null);
  const [notUserButClient, setNotUserButClient] = React.useState(false);

  const [loading, setLoading] = React.useState(true);

  //Loading form
  const [loadingForm, setLoadingForm] = React.useState(false);


  //Stepper
  const [step, setStep] = React.useState(0);

  //Substep 0
  const [subStep0, setSubStep0] = React.useState(0);


  //DataCardStep 0
  const [dataCardStep, setDataCardStep] = React.useState(0);
  const editDataCard= (cardStep:any) => {
    setDataCardStep(cardStep);
  };

  const [formErrors, setFormErrors] = useState([] as any);
  const [disabledTaxAddressProvince, setDisabledTaxAddressProvince] = useState(false);
  const [disabledCorrespondenceAddressProvince, setDisabledCorrespondenceAddressProvince] = useState(false);
  const [visibleOtherActivitySector, setVisibleOtherActivitySector] = useState(false);

  const onLoadedIframeSignaturit = () => {
    setLoadingForm(false);
  }

  useEffect(() => {
    if (
      formDataSignature.identifier &&
      signatureId &&
      documentId &&
      !isState
    ) {
      setIsState(true);
      setStateSignaturitWithoutToken({
        identifier: formDataSignature.identifier,
        document_id: documentId,
        signature_id: signatureId,
        state: 'FIRMADO'
      });
    }
  }, [signatureId, documentId]);

  useLayoutEffect(()=>{

    if(!isBot){
      if(router.routeInfo.search && router.routeInfo.search.indexOf('?id=')!=-1){
        let search = router.routeInfo.search.replace('?id=', '');
        const activate_id = search.indexOf('&product=') ? search.split('&product=')[0] : search;
        const product = search.indexOf('&product=') ? search.split('&product=')[1] : null;
        if(activate_id.trim().length>0){
          activateAccount({activate_id:activate_id.trim(), product: product}).then((result)=>{
            let redirectLogin = false;
            let title=intl.formatMessage({id:'page_register_registration_process_not_exists'});
            let message = '';
            if(result){

              switch(result.result){
                case 'ok':
                  title= intl.formatMessage({id:'page_register_registration_process_ok_title'});
                  message= intl.formatMessage({id:'page_register_registration_process_ok_message'});
                  const newValues = {
                    ...formDataSignature,
                    ['identifier']: result.identifier,
                    ['product']: result.product && result.product.split('@').length==4 ? result.product : ''
                  };
                  setFormDataSignature(newValues);

                  setStep(2);
                  break;
                case 'ko':
                  title= intl.formatMessage({id:'page_register_registration_process_ko'});
                  break;
                case 'timeout':
                  title= intl.formatMessage({id:'page_register_registration_process_timeout'});
                  break;
                case 'confirm':
                  redirectLogin=true;
                  title= intl.formatMessage({id:'page_register_registration_process_confirm_title'});
                  break;
              }
            }
            dialog({
              icon: <IconInformationUserLight color={skinVars.colors.brand} />,
              title: title,
              message: message,
              onAccept: redirectLogin ? ()=>{router.push(getUrlNavigation(LoginPage))} : undefined,
              acceptText: intl.formatMessage({id:'page_register_continue'})});

          }).finally(()=>{
            setLoading(false);
          });
        }else{
          setLoading(false);
        }
      }else{
        setLoading(false);
      }
    }else{
      setLoading(false);
    }
  }, []);

  useEffect(()=>{
      getMasterDatas('genres').then(options=>{
        setGenderOptions(options);
      });
      getMasterDatas('countries').then(options=>{
        setCountryOptions(options);
      });
      getMasterDatas('provinces').then(options=>{
        setProvinceOptions(options);
      });
      getMasterDatas('addressTypes').then(options=>{
        setViaOptions(options);
      });
      getMasterDatas('laboralSituation').then(options=>{
        setEmploymentSituationOptions(options);
      });
      getMasterDatas('laboralSectors').then(options=>{
        setActivitySectorOptionsInit(options);
      });



      window.addEventListener("message", (event) => {
        if(event?.data?.event === 'completed'){
          setSignatureId(event?.data?.signatureId);
          setDocumentId(event?.data?.documentId);
          setStep(4);
        }else if(event?.data?.event === 'declined'){
          const newValues = {
            ...formDataSignature ,
            ['sign_contract']: 'print_documentation',
          };
          setFormDataSignature(newValues);
          setStep(4);
        }
      });
  }, []);

  const getContractDocument= (): Promise<void> =>
    new Promise((resolve) => {
      setLoadingForm(true);
      trackEvent('acceso-registro-descargar_contrato');
      downloadContractDocument(intl.formatMessage({id: 'page_register_contract_document'}), formDataSignature.identifier).then(()=>{
        setLoadingForm(false);
      }).finally(()=>{
        resolve();
      });
  });
  const getAttachmentSignedDocument= (): Promise<void> =>
    new Promise((resolve) => {
      trackEvent('acceso-registro-descargar_adjuntos');
      setLoadingForm(true);
      downloadAttachmentSignedDocument(intl.formatMessage({id: 'page_register_attachment_signed_document'}), signatureId, documentId).then(()=>{
        setLoadingForm(false);
      }).finally(()=>{
        resolve();
      });
  });

  const getSignedDocument = (): Promise<void> =>
    new Promise((resolve) => {
      trackEvent('acceso-registro-descargar_documento_firmado');
      setLoadingForm(true);
      downloadSignedDocument(intl.formatMessage({id: 'page_register_signed_document'}), signatureId, documentId).then(()=>{
        setLoadingForm(false);
      }).finally(()=>{
        resolve();
      });
    });


  const continueContract = (): Promise<void> =>
    new Promise((resolve) => {
      //Login with
      trackEvent('acceso-registro-continuar_contratacion');
      if(formDataSignature.identifier && formDataSignature.product && formDataSignature.product.split('@').length==4){
          login({nif: formDataSignature.identifier,
                password: moment().unix().toString(),
                deviceId: getDeviceId(),
                platform: getPlatformDetail(),
                product: true
          }).then((client)=>{
            if(client){
              addUser(client);
              setContinueToContract();

              const product: Product = {
                cdCompania: parseInt(formDataSignature.product.split('@')[0]),
                cdPlan: formDataSignature.product.split('@')[1],
                cdFondo: formDataSignature.product.split('@')[2],
                cdSubplan: formDataSignature.product.split('@')[3],
              };
              setProductToContract(product);
              selectRoleUser('client')
              contract(product,
                ()=>{
                  removeUser();
                  removeContinueToContract();
                  router.push(getUrlNavigation(LoginPage));
                }).finally(()=>{
                  resolve();
                })
            }else{
              router.push(getUrlNavigation(LoginPage));
              resolve();
            }
        }).catch(()=>{
          router.push(getUrlNavigation(LoginPage));
          resolve();
        })
      }else{
        router.push(getUrlNavigation(LoginPage));
        resolve();
      }
  });

  const [formDataCheckClient, setFormDataCheckClient] = useState({
    idCard: "",
    birthDateConfirm: ""
  }as {
    idCard:string,
    birthDateConfirm:string,
    verificationCode?:string,
    contractProduct?:string,
    balanceAmount?:string,
    dateBalance?:string,
  });

  const [formData, setFormData] = useState({
    idCard: "",

    //Personal data
    dateOfExpiry : "",
    neverExpires: true,
    name: initNameRegister ? initNameRegister : '',
    surname: "",
    birthDate: "",
    countryOfBirth: process.env.REACT_APP_ID_PAIS_ESPANA ?? "11",
    nationality: process.env.REACT_APP_ID_PAIS_ESPANA ?? "11",
    countryOfResidence: process.env.REACT_APP_ID_PAIS_ESPANA ?? "11",
    gender: "",

    //Contact information
    telephone: "",
    mobile: "",
    email: "",

    //Tax address
    taxAddressCountry: process.env.REACT_APP_ID_PAIS_ESPANA ?? "11",
    taxAddressZipcode: "",
    taxAddressProvince: "",
    taxAddressLocality: "",
    taxAddressVia: "",
    taxAddressNameVia: "",
    taxAddressNumber: "",
    taxAddressFloor: "",

    taxAddressEqual: true,

    //Correspondence address
    correspondenceAddressCountry: process.env.REACT_APP_ID_PAIS_ESPANA ?? "11",
    correspondenceAddressZipcode: "",
    correspondenceAddressProvince: "",
    correspondenceAddressLocality: "",
    correspondenceAddressVia: "",
    correspondenceAddressNameVia: "",
    correspondenceAddressNumber: "",
    correspondenceAddressFloor: "",


    employmentSituation:"",
    activitySector:"",
    otherActivitySector:"",
    telefonicaGroupEmployee:false,
    usTaxIdentificationNumber:false,
    personPublicResponsibility:false,
    personPublicResponsibilityPosition: "",
    personPublicResponsibilityOrganism: "",
    familyPublicResponsibilityOrganism:false,
    relatedPublicResponsibilityOrganism:false,
    ongPositionLast2Years: false,
    informationTrueVerifiable:false,
    legalOriginIncome:false,
    acceptPrivacyPolicy:false,

    //Password access
    password:"",
    repeatPassword:"",


    //Operations key
    operationsKey:"",
    repeatOperationsKey:"",
  });

  const [formDataSignature, setFormDataSignature] = useState({
    //Sign contract
    sign_contract : "digitally",
    identifier:'',
    product:''
  });

  //Handle fields
  const handleError=(name:string) =>{
    if(formErrors && formErrors[name]){
      const error = formErrors[name];
      if(error){
        return intl.formatMessage({id: 'page_register_'+error.toLowerCase().replaceAll(' ', '_')});
      }
    }
    return null;
  }

  const validateField = (name:string, value:any)=>{
    switch(name){
      case 'name':
      case 'surname':
        if(!value || value.length===0){
          formErrors[name] = 'field required';
          return false;
        }
        break;
      case 'dateOfExpiry':
        const date = moment(value);
        const currentDate =  moment();
        if(date<currentDate){
          formErrors[name] = 'the date has expired';
          return false;
        }
        break;
      case 'birthDate':
        const birthDate = moment(value);
        const legalDate =  moment().subtract(18, 'years');
        if(birthDate>legalDate){
          formErrors[name] = 'is not legal age';
          return false;
        }
        break;
      case 'email':
        const expressionMail: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if(!expressionMail.test(value)){
          formErrors[name] = 'is not a valid email';
          return false;
        }
        break;
      case 'password':
          if(formData.repeatPassword.length>0 && formData.repeatPassword!=value){
            formErrors['repeatPassword'] = 'password not match';
            return false;
          }
          break;
      case 'repeatPassword':
          if(formData.password.length>0 && formData.password!=value){
            formErrors[name] = 'password not match';
            return false;
          }
          break;
      case 'operationsKey':
          if(formData.repeatOperationsKey.length>0 && formData.repeatOperationsKey!=value){
            formErrors['repeatOperationsKey'] = 'operations key not match';
            return false;
          }
        break;
      case 'repeatOperationsKey':
          if(formData.operationsKey.length>0 && formData.operationsKey!=value){
            formErrors[name] = 'operations key not match';
            return false;
          }
        break;
    }
    return true;
  }

  const handleChangeField= (evt:any) => {
      const { target } = evt;
      const { name, value } = target;
      const newValues = {
        ...formData,
        [name]: value,
      };
      setFormData(newValues);
      if(handleError(name)){
        delete formErrors[name];
      }
      validateField(name, value);
  };

  const handleChangeAnyField= (name:string, value:any) => {

    if(step===2){
      const newValues = {
        ...formDataSignature ,
        [name]: value,
      };
      setFormDataSignature(newValues);
      return;
    }


    const newValues = {
      ...formData,
      [name]: value,
    };
    const newValuesCC = {
      ...formDataCheckClient,
      [name]: value,
    };
    if(validateClientForm){
      setFormDataCheckClient(newValuesCC);
    }else{
      setFormData(newValues);
    }

    if(handleError(name)){
      delete formErrors[name];
    }

    switch(name){
      case 'contractProduct':
        let balance:string|undefined=undefined;
        if(value && validateClientForm && validateClientForm.contractProducts){
          const fechaSaldo = validateClientForm.contractProducts.find(item=>item.plan && formatContract(item)===value)?.fechaSaldo;

          if(fechaSaldo){
            balance = moment(fechaSaldo).format("DD/MM/YYYY");
          }
        }
        let newValuesDateBalance = {
          ...newValuesCC,
          ['dateBalance']: balance
        };
        setFormDataCheckClient(newValuesDateBalance);
        break;
      case 'neverExpires':
        validateField('dateOfExpiry', formData.dateOfExpiry);
        if(value===true){
          delete formErrors['dateOfExpiry'];
        }
        break;
      case 'taxAddressCountry':
        const disabledTaxAddressProvince = value!=process.env.REACT_APP_ID_PAIS_ESPANA ?? "11";
        const newValuesTaxAddress = {
          ...newValues,
          ['taxAddressProvince']: disabledTaxAddressProvince ? '' : formData.taxAddressProvince
        };
        setFormData(newValuesTaxAddress);
        setDisabledTaxAddressProvince(disabledTaxAddressProvince);
        break;
      case 'correspondenceAddressCountry':
          const disabledCorrespondenceAddress = value!=process.env.REACT_APP_ID_PAIS_ESPANA ?? "11";
          const newValuesCorrespondenceAddress = {
            ...newValues,
            ['correspondenceAddressProvince']: disabledCorrespondenceAddress ? '' : formData.correspondenceAddressProvince
          };
          setFormData(newValuesCorrespondenceAddress);
          setDisabledCorrespondenceAddressProvince(disabledCorrespondenceAddress);
          break;
      case 'activitySector':
          switch(value){
            case 'Otros':
            case 'Other':
              setVisibleOtherActivitySector(true);
              break;
            default:
              setVisibleOtherActivitySector(false);
              break;
          }
          break;
      case 'employmentSituation':
          switch(value){
            case 'unemployed':
            case 'Householder':
            case 'Retired':
            case 'Student / Minor':
            case 'Desempleado':
            case 'Amo de casa':
            case 'Jubilado':
            case 'Estudiante/Menor de edad':
              const activitySectorOptionsNone = activitySectorOptionsInit.filter(item=>item.valor==='None' || item.valor==='Ninguna');
              const activitySectorNone = activitySectorOptionsNone.find(item=>item.valor==='None' || item.valor==='Ninguna');
              setActivitySectorOptions(activitySectorOptionsNone);
              const newValuesActivitySectorNone = {
                ...newValues,
                ['activitySector']: activitySectorNone ? activitySectorNone.valor : ''
              };
              setFormData(newValuesActivitySectorNone);
              break;
            default:
              const activitySectorOptions = activitySectorOptionsInit.filter(item=>item.valor!=='None' && item.valor!=='Ninguna');
              const activitySector = activitySectorOptions.find(item=>item.valor===formData.activitySector);
              setActivitySectorOptions(activitySectorOptions);
              const newValuesActivitySector = {
                ...newValues,
                ['activitySector']: activitySector ? activitySector.valor : ''
              };
              setFormData(newValuesActivitySector);
              break;
          }
          break;
    }
    validateField(name, value);
  };

  const handleZipcode = (evt:any) =>{
    try{
      const { target } = evt;
      const { name, value } = target;

      if(value && value.toString().trim().length>0){
        const taxAddressCountry = formData.taxAddressCountry;
        const correspondenceAddressCountry = formData.correspondenceAddressCountry;
        if (taxAddressCountry === process.env.REACT_APP_ID_PAIS_ESPANA && correspondenceAddressCountry === process.env.REACT_APP_ID_PAIS_ESPANA) {
          setLoadingForm(true);
          getProvinceCity(value.toString().trim()).then(zipData=>{
            if(zipData && zipData.locality && zipData.province){
              const province = provinceOptions.find(item=>item.descripcion===zipData.province);

              switch(name){
                case 'taxAddressZipcode':
                  const newValuesTaxAddress = {
                    ...formData,
                    ['taxAddressLocality']: zipData.locality,
                    ['taxAddressProvince']: province?.valor ?? formData.taxAddressProvince
                  };
                  setFormData(newValuesTaxAddress);
                  break;
                case 'correspondenceAddressZipcode':
                  const newValuesCorrespondenceAddress = {
                    ...formData,
                    ['correspondenceAddressLocality']: zipData.locality,
                    ['correspondenceAddressProvince']: province?.valor ?? formData.correspondenceAddressProvince
                  };
                  setFormData(newValuesCorrespondenceAddress);
                  break;
              }
            }
            setLoadingForm(false);
          });
        }
      }
    }catch(err){
      if(err){
        trackError(JSON.stringify(err));
      }
    }
  }

  //Submit disabled
  const isSubmitDisabled = () => {
    const countryDefault = (process.env.REACT_APP_ID_PAIS_ESPANA ?? "11");
    switch(step){
      case 0:
        switch(subStep0){
          case 0:
            if(validateClientForm!=null){
                return formDataCheckClient.idCard.length===0 || formDataCheckClient.birthDateConfirm.length===0 || (validateClientForm.smsSent && (formDataCheckClient.verificationCode==undefined || formDataCheckClient.verificationCode.length===0)) || (validateClientForm.contractProducts && (formDataCheckClient.contractProduct==undefined || formDataCheckClient.contractProduct.length===0 || formDataCheckClient.balanceAmount==undefined || formDataCheckClient.balanceAmount.length===0));
            }
            return formData.idCard.length === 0;
          case 1:
            switch(dataCardStep){
              case 0:
                return formData.idCard.length === 0 ||
                      (!formData.neverExpires && (formData.dateOfExpiry.length===0 || handleError('dateOfExpiry')!==null )) ||

                      !notUserButClient && (formData.name.length === 0 || handleError('name')!==null) ||
                      !notUserButClient && (formData.surname.length === 0 || handleError('surname')!==null) ||
                      !notUserButClient && (formData.birthDate.length === 0 || handleError('birthDate')!==null) ||
                      formData.countryOfBirth.length === 0 || handleError('countryOfBirth')!==null ||
                      formData.nationality.length===0 || handleError('nationality')!==null ||
                      formData.countryOfResidence.length === 0 || handleError('countryOfResidence')!==null ||
                      formData.gender.length === 0 || handleError('gender')!==null;
              case 1:
                return handleError('telephone')!==null ||
                      formData.mobile.length === 0 || handleError('mobile')!==null ||
                      formData.email.length === 0 || handleError('email')!==null ||
                      !notUserButClient && (formData.taxAddressCountry.length === 0 || handleError('taxAddressCountry')!==null) ||
                      !notUserButClient && (formData.taxAddressZipcode.length === 0 || handleError('taxAddressZipcode')!==null) ||
                      !notUserButClient && ((formData.taxAddressCountry===countryDefault && (formData.taxAddressProvince.length === 0 || handleError('taxAddressProvince')!==null))) ||
                      !notUserButClient && (formData.taxAddressLocality.length===0 || handleError('taxAddressLocality')!==null) ||
                      !notUserButClient && (formData.taxAddressVia.length === 0 || handleError('taxAddressVia')!==null) ||
                      !notUserButClient && (formData.taxAddressNameVia.length === 0 || handleError('taxAddressNameVia')!==null) ||
                      !notUserButClient && (formData.taxAddressNumber.length === 0 || handleError('taxAddressNumber')!==null)
                      || (formData.taxAddressEqual!==true && (
                        formData.correspondenceAddressCountry.length === 0 || handleError('correspondenceAddressCountry')!==null ||
                        formData.correspondenceAddressZipcode.length === 0 || handleError('correspondenceAddressZipcode')!==null ||
                        (formData.correspondenceAddressCountry===countryDefault && (formData.correspondenceAddressProvince.length === 0 || handleError('correspondenceAddressProvince')!==null)) ||
                        formData.correspondenceAddressLocality.length === 0 || handleError('correspondenceAddressLocality')!==null ||
                        formData.correspondenceAddressVia.length === 0 || handleError('correspondenceAddressVia')!==null ||
                        formData.correspondenceAddressNameVia.length === 0 || handleError('correspondenceAddressNameVia')!==null ||
                        formData.correspondenceAddressNumber.length === 0 || handleError('correspondenceAddressNumber')!==null
                        ));
              case 2:
                return formData.employmentSituation.length === 0 || handleError('employmentSituation')!==null ||
                      formData.activitySector.length === 0 || handleError('activitySector')!==null ||
                      ((formData.activitySector==='Other' || formData.activitySector==='Otros') && formData.otherActivitySector.length===0) ||
                      (formData.personPublicResponsibility===true && (formData.personPublicResponsibilityPosition.length === 0 || handleError('personPublicResponsibilityPosition')!==null || formData.personPublicResponsibilityOrganism.length === 0 || handleError('personPublicResponsibilityOrganism')!==null)) ||
                      formData.informationTrueVerifiable===false ||
                      formData.legalOriginIncome===false ||
                      formData.acceptPrivacyPolicy===false;
            }
        }
        break;
      case 1:
        return formData.password.length===0 || handleError('password')!==null ||
               formData.repeatPassword.length === 0 || handleError('repeatPassword')!==null ||
               formData.operationsKey.length === 0 || handleError('operationsKey')!==null ||
               formData.repeatOperationsKey.length === 0 || handleError('repeatOperationsKey')!==null;
      case 2:
        return formDataSignature.identifier.length===0 || formDataSignature.sign_contract.length===0;
    }
    return false;
  }
  const verifyIdCardDNIe = (): Promise<void> =>
    new Promise((resolve) => {
      trackEvent('acceso-registro-iniciar_dnie');
      verifyIdCard(true).finally(()=>{
        resolve();
      });
    });

  const verifyIdCard = (electronic:boolean=false): Promise<void> =>
    new Promise((resolve) => {
        checkIdCard({
          "idcard": electronic ? '' : formData.idCard,
          "electronic": electronic,
          "deviceId": getDeviceId(),
          "platform": getPlatform()
        }).then((result)=>{
          setLoadingForm(false);
          if(result.notUserButClient){
            setNotUserButClient(true);
            if((result.contractProducts!=null && result.contractProducts.length>0) || result.emailSent || result.smsSent){
              setValidateClientForm(result);
              const newValues = {
                ...formDataCheckClient,
                ['idCard']: result.idCard ?? ''
              }
              setFormDataCheckClient(newValues);
            }else if(result.user){
              setValidateClientForm(null);
              const user = result.user;
              setDisabledTaxAddressProvince(false);
              const newValues = {
                ...formData,
                ['dateOfExpiry'] : !(user?.noCaducaNunca ?? false) ? user && user.fechaCaducidadNIF ? (moment.unix((user.fechaCaducidadNIF as any).timestamp).format("YYYY-MM-DD").toString()) : (user && user.fechaCaducidadTarjetaResidenteTS ? (moment.unix((user.fechaCaducidadTarjetaResidenteTS as any).timestamp).format("YYYY-MM-DD").toString()) : '') : '',
                ['neverExpires']: user?.noCaducaNunca ?? false,
                ['name']: user?.nombre ?? "",
                ['surname']: user?.apellidos ?? "",
                ['birthDate']: user && user.fechaNacimiento ? moment(user.fechaNacimiento as any).format("YYYY-MM-DD").toString() : '',
                ['countryOfBirth']: user?.paisNacimiento?.toString() ?? (process.env.REACT_APP_ID_PAIS_ESPANA ?? "11"),
                ['nationality']: user?.nacionalidad?.toString()?? (process.env.REACT_APP_ID_PAIS_ESPANA ?? "11"),
                ['countryOfResidence']: user?.paisResidencia?.toString()?? (process.env.REACT_APP_ID_PAIS_ESPANA ?? "11"),
                ['gender']: user?.genero ?? "",
                ['telephone']: user?.telefono ?? "",
                ['mobile']: user?.movil ?? "",
                ['email']: user?.email?.toLowerCase() ?? "",
                ['taxAddressCountry']: user?.datosFiscales?.domicilioPais?.toString() ?? "",
                ['taxAddressZipcode']: user?.datosFiscales?.domicilioCP ?? "",
                ['taxAddressProvince']: user?.datosFiscales?.domicilioProvincia?.toString().padStart(2, '0') ?? "",
                ['taxAddressLocality']: user?.datosFiscales?.domicilioPoblacion ?? "",
                ['taxAddressVia']: user?.datosFiscales?.domicilioTipoVia ?? "",
                ['taxAddressNameVia']: user?.datosFiscales?.domicilioVia ?? "",
                ['taxAddressNumber']: user?.datosFiscales?.domicilioNumero ?? "",
                ['taxAddressFloor']: user?.datosFiscales?.domicilioPiso ?? ""
              }
              setFormData(newValues);
              setSubStep0(subStep0+1);
            }
          }else if(result.notClientWithValidNif){
            if(result.idCard){
              const newValues = {
                ...formData,
                idCard: result.idCard
              }
              setFormData(newValues);
            }
            if(result.foreingerResidentInSpain){
              //TO DO:
            }
            setSubStep0(subStep0+1);
          }else if(result.registrationProcessNotCompleted){
            dialog({
              icon: <IconInformationUserLight color={skinVars.colors.brand} />,
              title: intl.formatMessage({id:'page_register_registration_process_not_completed'}),
              message: '',
              acceptText: intl.formatMessage({id:'page_register_invalid_action_continue'})});
            resolve();

          }else if(result.spanishUnder14years){
            dialog({
              icon: <IconInformationUserLight color={skinVars.colors.brand} />,
              title: intl.formatMessage({id:'page_register_invalid_idcard_under14'}),
              message: '',
              acceptText: intl.formatMessage({id:'page_register_invalid_action_continue'})});
            resolve();
          }else if(result.isCompany){
            dialog({
              icon: <IconInformationUserLight color={skinVars.colors.brand} />,
              title: intl.formatMessage({id:'page_register_invalid_idcard_cif'}),
              message: '',
              acceptText: intl.formatMessage({id:'page_register_invalid_action_continue'})});
            resolve();
          }else{
            dialog({
              icon: <IconInformationUserLight color={skinVars.colors.brand} />,
              title: intl.formatMessage({id:'page_register_invalid_idcard_registered'}),
              message: '',
              onAccept: ()=>{router.push(getUrlNavigation(LoginPage))},
              acceptText: intl.formatMessage({id:'page_register_invalid_idcard_registered_action_login'})});
            resolve();
          }
          resolve();
        }).catch((reason)=>{
          setLoadingForm(false);
          const userInvalid = reason.response.data as UserInvalidException;
          if(userInvalid && userInvalid.message){
            switch(userInvalid.message){
              case 'NIF is not valid':
                dialog({
                  icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                  title: intl.formatMessage({id:'page_register_invalid_idcard'}),
                  message: '',
                  acceptText: intl.formatMessage({id:'page_register_invalid_action_continue'})});
                  resolve();
                  break;
            }
            resolve();
            return;
          }
        }).finally(()=>{
          setLoadingForm(false);
          resolve();
          return;
        });
    });

  //Submit
  const handleSubmit = (data: any): Promise<void> =>
        new Promise((resolve) => {
            setLoadingForm(true);
            switch(step){
              case 0:
                if(subStep0===0){

                  if(validateClientForm!=null){

                    const params = {
                      ...formDataCheckClient,
                      ['balanceAmount']: formDataCheckClient.balanceAmount && formDataCheckClient.balanceAmount.length>0 ? parseFloat(formDataCheckClient.balanceAmount.replace(',', '.')) : null
                    };

                    checkDataRegister(params).then((result)=>{
                      if(result && result.resultChecked && result.user){
                        setValidateClientForm(null);
                        const user = result.user;
                        setDisabledTaxAddressProvince(false);
                        const newValues = {
                          ...formData,
                          ['dateOfExpiry'] : !(user?.noCaducaNunca ?? false) ? user && user.fechaCaducidadNIF ? (moment.unix((user.fechaCaducidadNIF as any).timestamp).format("YYYY-MM-DD").toString()) : (user && user.fechaCaducidadTarjetaResidenteTS ? (moment.unix((user.fechaCaducidadTarjetaResidenteTS as any).timestamp).format("YYYY-MM-DD").toString()) : '') : '',
                          ['neverExpires']: user?.noCaducaNunca ?? false,
                          ['name']: user?.nombre ?? "",
                          ['surname']: user?.apellidos ?? "",
                          ['birthDate']: user && user.fechaNacimiento ? moment(user.fechaNacimiento as any).format("YYYY-MM-DD").toString() : '',
                          ['countryOfBirth']: user?.paisNacimiento?.toString() ?? (process.env.REACT_APP_ID_PAIS_ESPANA ?? "11"),
                          ['nationality']: user?.nacionalidad?.toString()?? (process.env.REACT_APP_ID_PAIS_ESPANA ?? "11"),
                          ['countryOfResidence']: user?.paisResidencia?.toString()?? (process.env.REACT_APP_ID_PAIS_ESPANA ?? "11"),
                          ['gender']: user?.genero ?? "",
                          ['telephone']: user?.telefono ?? "",
                          ['mobile']: user?.movil ?? "",
                          ['email']: user?.email?.toLowerCase() ?? "",
                          ['taxAddressCountry']: user?.datosFiscales?.domicilioPais?.toString() ?? "",
                          ['taxAddressZipcode']: user?.datosFiscales?.domicilioCP ?? "",
                          ['taxAddressProvince']: user?.datosFiscales?.domicilioProvincia?.toString().padStart(2, '0') ?? "",
                          ['taxAddressLocality']: user?.datosFiscales?.domicilioPoblacion ?? "",
                          ['taxAddressVia']: user?.datosFiscales?.domicilioTipoVia ?? "",
                          ['taxAddressNameVia']: user?.datosFiscales?.domicilioVia ?? "",
                          ['taxAddressNumber']: user?.datosFiscales?.domicilioNumero ?? "0",
                          ['taxAddressFloor']: user?.datosFiscales?.domicilioPiso ?? ""
                        }
                        setFormData(newValues);
                        setSubStep0(subStep0+1);
                      }else{
                        dialog({
                          icon: <IconInformationUserLight color={skinVars.colors.brand} />,
                          title: intl.formatMessage({id:'page_check_register_error_title'}),
                          message: '',
                          acceptText: intl.formatMessage({id:'page_register_invalid_action_continue'})});
                        resolve();
                      }
                    }).finally(()=>{
                      setLoadingForm(false);
                      resolve();
                    })
                  }else{
                    verifyIdCard().finally(()=>{
                      resolve();
                    });
                  }

                }else{
                  if(dataCardStep<2){

                    const product = getProductToContract()
                    if(dataCardStep==1){
                      if(product && product.individual && product.individual===process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS){
                        const option= employmentSituationOptions.find(item=>item.valor==='Trabajo por cuenta propia' || item.valor==='Self-employment');
                        if(option){
                          setEmploymentSituationOptions([option]);
                          handleChangeAnyField('employmentSituation', option.valor);
                        }
                      }
                    }

                    setDataCardStep(dataCardStep+1);
                    scrollToTop();
                    resolve();
                    setLoadingForm(false);
                  }else{
                    const newValues = {
                      ...formData,
                      ['dateOfExpiry']: formData.neverExpires ? null : formData.dateOfExpiry,
                      ['countryOfBirth']: parseInt(formData.countryOfBirth),
                      ['taxAddressNumber']: parseInt(formData.taxAddressNumber),
                      ['nationality']: parseInt(formData.nationality),
                      ['countryOfResidence']: parseInt(formData.countryOfResidence),
                      ['taxAddressCountry']: parseInt(formData.taxAddressCountry),
                      ['taxAddressProvince']: formData.taxAddressProvince,
                      ['correspondenceAddressCountry']: formData.correspondenceAddressCountry.length>0 ? parseInt(formData.correspondenceAddressCountry) : null,
                      ['correspondenceAddressProvince']: formData.correspondenceAddressProvince.length>0 ? formData.correspondenceAddressProvince : null,
                      ['correspondenceAddressNumber']: formData.correspondenceAddressNumber.length>0 ? parseInt(formData.correspondenceAddressNumber) : null,
                    };

                    register(newValues).then((result)=>{
                      if(result.result){
                        setStep(step+1)
                      }else if(result.errors){
                        const errors = result.errors as any;
                        if(errors['dateOfExpiry'] || errors['name'] || errors['surname'] || errors['birthDate'] || errors['countryOfBirth'] || errors['nationality'] || errors['countryOfResidence'] || errors['gender']){
                          setDataCardStep(0);
                        }else if(errors['telephone'] || errors['mobile'] || errors['email'] ||
                                errors['taxAddressCountry'] || errors['taxAddressZipcode'] || errors['taxAddressCountry'] || errors['taxAddressProvince'] || errors['taxAddressLocality'] || errors['taxAddressVia'] || errors['taxAddressNameVia'] || errors['taxAddressNumber'] || errors['taxAddressFloor'] ||
                                errors['correspondenceAddressCountry'] || errors['correspondenceAddressZipcode'] || errors['correspondenceAddressCountry'] || errors['correspondenceAddressProvince'] || errors['correspondenceAddressLocality'] || errors['correspondenceAddressVia'] || errors['correspondenceAddressNameVia'] || errors['correspondenceAddressNumber'] || errors['correspondenceAddressFloor']){
                          setDataCardStep(1);
                        }else{
                          setDataCardStep(2);
                        }
                        setFormErrors(result.errors);
                      }
                      scrollToTop();
                      resolve();
                      setLoadingForm(false);
                    });
                  }
                }
                break;
              case 1:
                const product = getProductToContract()
                let newValues = {
                  ...formData,
                  ['dateOfExpiry']: formData.neverExpires ? null : formData.dateOfExpiry,
                  ['countryOfBirth']: parseInt(formData.countryOfBirth),
                  ['taxAddressNumber']: parseInt(formData.taxAddressNumber),
                  ['nationality']: parseInt(formData.nationality),
                  ['countryOfResidence']: parseInt(formData.countryOfResidence),
                  ['taxAddressCountry']: parseInt(formData.taxAddressCountry),
                  ['taxAddressProvince']: formData.taxAddressProvince,
                  ['correspondenceAddressCountry']: formData.correspondenceAddressCountry.length>0 ? parseInt(formData.correspondenceAddressCountry) : null,
                  ['correspondenceAddressProvince']: formData.correspondenceAddressProvince.length>0 ? formData.correspondenceAddressProvince : null,
                  ['correspondenceAddressNumber']: formData.correspondenceAddressNumber.length>0 ? parseInt(formData.correspondenceAddressNumber) : null,

                  ['cdCompany']: product?.cdCompania ?? null,
                  ['cdPlan']: product?.cdPlan ?? null,
                  ['cdFund']: product?.cdFondo ?? null,
                  ['cdSubplan']: product?.cdSubplan ?? null
                };

                register(newValues).then((result)=>{
                  if(result.result){
                      removeProductToContract();
                      dialog({
                        title: intl.formatMessage({id:'page_register_registration_process_completed_title'}),
                        subtitle: intl.formatMessage({id:'page_register_registration_process_completed_subtitle'}),
                        message: intl.formatMessage({id:'page_register_registration_process_completed_message'}),
                        forceWeb: true,
                        showCancel: false,
                        acceptText: intl.formatMessage({id:'page_register_registration_process_completed_action'}),
                        onAccept: ()=>{router.push(getUrlNavigation(LoginPage))}
                    })
                  }else if(result.errors){
                    const errors = result.errors as any;
                    setFormErrors(result.errors);
                  }
                  scrollToTop();
                  resolve();
                  setLoadingForm(false);
                });
                break;

              case 2:
                setLoadingForm(true);
                setSignContract(formDataSignature).then((result)=>{
                  if(result){
                    if(result.result==='ok'){
                      switch(formDataSignature.sign_contract){
                        case 'print_documentation':
                          trackEvent('acceso-registro-imprimir');
                          setStep(4);
                          setLoadingForm(false);
                          getContractDocument();
                          break;
                        case 'digitally':
                          trackEvent('acceso-registro-firmar');
                          setUrlSignature(result.urlSignature);
                          setStep(3);
                          setLoadingForm(false);
                          break;
                      }
                    }
                  }
                  scrollToTop();
                  resolve();
                }).finally(()=>{
                  resolve();
                });
              break
              default:
                setStep(step+1);
                scrollToTop();
                resolve();
                setLoadingForm(false);
                break;
            }
        });

  //Return personal data
  const handleReturnPersonalData= () => {
    setSubStep0(1); setDataCardStep(0); setStep(0);
  };

  return (

    <LayoutPublic
      seo={{metaTitle: intl.formatMessage({id:'page_register_seo_title'})}}
      header={{small:true, title: intl.formatMessage({id:'page_register_title'})}}
      hideFooter={true}

    >

          <ResponsiveLayout className='content' fullWidth={true} backgroundColor={skinVars.colors.backgroundAlternative}>
            <LoadingBar  visible={loading || loadingForm} />
            {loading ?
              <>
              <ResponsiveLayout fullWidth className={'wrapper-stepper'} backgroundColor={skinVars.colors.backgroundAlternative}>
                <div className='stepper'>
                  <SkeletonLine></SkeletonLine>
                </div>
              </ResponsiveLayout>
              <ResponsiveLayout>
                  <div className={'form-register-page '+(screenSize.isMobile ? 'mobile' : '')}>
                    <ResponsiveLayout className='form'>
                      <SkeletonRectangle height={300}></SkeletonRectangle>
                    </ResponsiveLayout>
                  </div>
                </ResponsiveLayout>
              </>
              :
              <>
              {step<3 &&
                <ResponsiveLayout fullWidth className={'wrapper-stepper'} backgroundColor={skinVars.colors.backgroundAlternative}>
                  <div className='stepper'>
                      <Stepper currentIndex={step} steps={[intl.formatMessage({id:'page_register_step_personal_data'}), intl.formatMessage({id:'page_register_step_passwords'}), intl.formatMessage({id:'page_register_step_signs'})]} aria-label="Progress" />
                  </div>
                </ResponsiveLayout>
              }

              <ResponsiveLayout>
                  <div className={'form-register-page '+(urlSignature && urlSignature.length>0 ? 'with-iframe ' : '')+(screenSize.isMobile ? 'mobile' : '')}>
                        {step===0 &&
                          <ResponsiveLayout className='form'>
                            {subStep0=== 0 &&
                              (validateClientForm ?
                                <DataCard
                                  title= {intl.formatMessage({id:'page_check_register_welcome_title'})}
                                  description={''}
                                  extra={
                                    <>
                                      <Box paddingTop={32}>
                                        <Stack space={16}>
                                          <Form autoJump onSubmit={handleSubmit} className={loadingForm ? 'loading' : ''}>

                                            <Stack space={32}>
                                              <GridLayout verticalSpace={16} template='6+6'
                                                left={<TextField name="idCard" value={formDataCheckClient.idCard} disabled label={intl.formatMessage({id:'page_register_id_card'})} fullWidth/>}
                                                right={<DateField fullWidth name="birthDateConfirm" error={handleError('birthDateConfirm')!==null} helperText={handleError('birthDateConfirm')??undefined}  label={intl.formatMessage({id:'page_register_birthdate'})} onChangeValue={(val)=>{handleChangeAnyField('birthDateConfirm', val)}} />}/>

                                              {validateClientForm.smsSent &&
                                                <Stack space={8}>
                                                  <Callout
                                                  icon={<IconMobileDeviceRegular size={48} />}
                                                  title={intl.formatMessage({id:'page_check_register_sent_phone_title'}, {phone_numbers:validateClientForm.phone_numbers})}
                                                  description={''}/>

                                                  <TextField autoComplete={'off'} fullWidth name="verificationCode" error={handleError('verificationCode')!==null} helperText={handleError('verificationCode')??undefined}  label={intl.formatMessage({id:'page_register_verification_code'})} onChangeValue={(val)=>{handleChangeAnyField('verificationCode', val)}} />
                                                </Stack>
                                              }

                                              {validateClientForm.contractProducts &&
                                                <GridLayout verticalSpace={16} template='6+6'
                                                  left={<Select fullWidth label={intl.formatMessage({id:'page_register_select_product'})} name={'contractProduct'} error={handleError('contractProduct')!==null} helperText={handleError('contractProduct')??undefined} options={validateClientForm.contractProducts.map(item => ({text:item.numContrato+' - '+item.plan?.descripcion, value: formatContract(item) }))} onChangeValue={(val:any)=> handleChangeAnyField('contractProduct', val)}></Select>}
                                                  right={formDataCheckClient.contractProduct ?<DecimalField maxDecimals={2} fullWidth error={handleError('balanceAmount')!==null} helperText={handleError('balanceAmount')??undefined} name="balanceAmount" label={intl.formatMessage({id:'page_register_balance'}, {date: formDataCheckClient.dateBalance ?? ''})} onChangeValue={(val:any)=> handleChangeAnyField('balanceAmount', val)} /> : <></>}
                                                />
                                              }

                                              <ButtonLayout align='left'>
                                                <ButtonPrimary disabled={isSubmitDisabled()} submit>{intl.formatMessage({id:'page_register_actions_continue'})}</ButtonPrimary>
                                              </ButtonLayout>
                                            </Stack>
                                          </Form>
                                        </Stack>
                                      </Box>
                                    </>
                                  }></DataCard>
                              :
                                <DataCard
                                  title= {intl.formatMessage({id:'page_register_welcome_title'},{name: (initNameRegister ? ' '+initNameRegister : '')})}
                                  description={intl.formatMessage({id:'page_register_welcome_description'})}
                                  extra={
                                    <>
                                      <Box paddingTop={32}>
                                        <Stack space={16}>
                                          <Form autoJump onSubmit={handleSubmit} initialValues={formData} className={loadingForm ? 'loading' : ''}>

                                            <Stack space={32}>
                                              <GridLayout verticalSpace={16} template='6+6'
                                                left={
                                                  <TextField name="idCard" label={intl.formatMessage({id:'page_register_id_card'})} onChange={handleChangeField} fullWidth/>
                                                }
                                                right={
                                                !isNativeApp &&
                                                  <Box paddingY={4}>
                                                    <ButtonLayout align='full-width'>
                                                      <ButtonSecondary onPress={verifyIdCardDNIe}> <IconIdCardFilled color="currentColor" /> <Text3 regular color="currentColor">{intl.formatMessage({id:'page_register_actions_dnie'})}</Text3></ButtonSecondary>
                                                    </ButtonLayout>
                                                  </Box>
                                                }></GridLayout>
                                              <ButtonLayout align='left'>
                                                <ButtonPrimary disabled={isSubmitDisabled()} submit>{intl.formatMessage({id:'page_register_actions_continue'})}</ButtonPrimary>
                                              </ButtonLayout>
                                            </Stack>
                                          </Form>
                                        </Stack>
                                      </Box>
                                    </>
                                  }></DataCard>)
                            }
                            <Stack space={16}>
                            {subStep0=== 1 &&
                              <Boxed>
                                <Box padding={32}>
                                  <Form autoJump onSubmit={handleSubmit} initialValues={formData} className={loadingForm ? 'loading' : ''}>
                                    <Stack space={32}>
                                      <Inline space={'between'} alignItems='center'>
                                        <Inline space={8} alignItems='center'>
                                          <Text4 medium color={dataCardStep===0 ? skinVars.colors.buttonPrimaryBackground : skinVars.colors.neutralMedium}>{intl.formatMessage({id:'page_register_personal_data'})} </Text4>
                                          {dataCardStep>0 &&
                                            <IconCheckedRegular size={24} color={skinVars.colors.buttonPrimaryBackground}></IconCheckedRegular>
                                          }
                                        </Inline>
                                        {dataCardStep>0 &&
                                          <ButtonLink onPress={()=>editDataCard(0)}>{intl.formatMessage({id:'page_register_actions_edit'})}</ButtonLink>
                                        }
                                      </Inline>
                                      {dataCardStep===0 &&
                                        <>
                                        <Stack space={16}>
                                          <GridLayout verticalSpace={16} template='6+6'
                                            left={
                                              <TextField fullWidth readOnly name="idCard" label={intl.formatMessage({id:'page_register_id_card'})} onChange={handleChangeField} />
                                            }
                                            right={
                                              <Stack space={8}>
                                                <DateField disabled={formData.neverExpires} error={handleError('dateOfExpiry')!==null} helperText={handleError('dateOfExpiry')??undefined}  fullWidth name="dateOfExpiry" label={intl.formatMessage({id:'page_register_date_of_expiry'})} onChange={handleChangeField} />
                                                <Checkbox onChange={(val:any)=> handleChangeAnyField('neverExpires', val)} name="neverExpires" render={({controlElement, labelId}:any) => (
                                                    <Inline alignItems="center" space={16}>
                                                      {controlElement}
                                                      <Text1 regular>{intl.formatMessage({id:'page_register_never_expires'})}</Text1>
                                                    </Inline>
                                                )}></Checkbox>
                                              </Stack>
                                            }></GridLayout>

                                          <GridLayout verticalSpace={16} template='6+6'
                                            left={
                                              <TextField disabled={notUserButClient} fullWidth name="name" error={handleError('name')!==null} helperText={handleError('name')??undefined} label={intl.formatMessage({id:'page_register_name'})} onChange={handleChangeField} />
                                            }
                                            right={
                                              <TextField disabled={notUserButClient} fullWidth name="surname" error={handleError('surname')!==null} helperText={handleError('surname')??undefined} label={intl.formatMessage({id:'page_register_surname'})} onChange={handleChangeField} />
                                            }></GridLayout>

                                          <GridLayout verticalSpace={16} template='6+6'
                                            left={
                                              <DateField disabled={notUserButClient} fullWidth name="birthDate" error={handleError('birthDate')!==null} helperText={handleError('birthDate')??undefined}  label={intl.formatMessage({id:'page_register_birthdate'})} onChange={handleChangeField} />
                                            }
                                            right={
                                              <Select fullWidth label={intl.formatMessage({id:'page_register_country_of_birth'})} name={'countryOfBirth'} error={handleError('countryOfBirth')!==null} helperText={handleError('countryOfBirth')??undefined} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('countryOfBirth', val)}></Select>
                                            }></GridLayout>

                                          <GridLayout verticalSpace={16} template='6+6'
                                            left={
                                              <Select fullWidth label={intl.formatMessage({id:'page_register_nationality'})} name={'nationality'} error={handleError('nationality')!==null} helperText={handleError('nationality')??undefined} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('nationality', val)}></Select>
                                            }
                                            right={
                                              <Select fullWidth label={intl.formatMessage({id:'page_register_country_of_residence'})} name={'countryOfResidence'} error={handleError('countryOfResidence')!==null} helperText={handleError('countryOfResidence')??undefined} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('countryOfResidence', val)}></Select>
                                            }></GridLayout>


                                          <GridLayout verticalSpace={16} template='6+6'
                                            left={
                                              <Select fullWidth label={intl.formatMessage({id:'page_register_gender'})} name={'gender'} error={handleError('gender')!==null} helperText={handleError('gender')??undefined} options={genderOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('gender', val)}></Select>
                                            }
                                            right={
                                              <></>
                                            }></GridLayout>

                                            <Box paddingTop={16}>
                                              <ButtonLayout align='left'>
                                                <ButtonPrimary disabled={isSubmitDisabled()} submit>{intl.formatMessage({id:'page_register_actions_continue_to_contact_details'})}</ButtonPrimary>
                                              </ButtonLayout>
                                            </Box>
                                          </Stack>
                                        </>
                                      }
                                    </Stack>
                                  </Form>
                                </Box>
                              </Boxed>
                            }
                            {subStep0=== 1 &&
                              <Boxed>
                                <Box padding={32}>
                                  <Form autoJump onSubmit={handleSubmit} initialValues={formData} className={loadingForm ? 'loading' : ''}>
                                  <Stack space={32}>
                                    <Inline space={'between'} alignItems='center'>
                                      <Inline space={8} alignItems='center'>
                                        <Text4 medium color={dataCardStep===1 ? skinVars.colors.buttonPrimaryBackground : skinVars.colors.neutralMedium}>{intl.formatMessage({id:'page_register_contact_details_address'})} </Text4>
                                        {dataCardStep>1 &&
                                          <IconCheckedRegular size={24} color={skinVars.colors.buttonPrimaryBackground}></IconCheckedRegular>
                                        }
                                      </Inline>
                                      {dataCardStep>1 &&
                                        <ButtonLink onPress={()=>editDataCard(1)}>{intl.formatMessage({id:'page_register_actions_edit'})}</ButtonLink>
                                      }
                                    </Inline>
                                    {dataCardStep===1 &&
                                      <>
                                      <Stack space={16}>
                                          <GridLayout verticalSpace={16} template='6+6'
                                            left={
                                              <TextField fullWidth name="telephone" optional error={handleError('telephone')!==null} helperText={handleError('telephone')??undefined} label={intl.formatMessage({id:'page_register_telephone'})} onChange={handleChangeField} />
                                            }
                                            right={
                                              <TextField fullWidth name="mobile" error={handleError('mobile')!==null} helperText={handleError('mobile')??undefined} label={intl.formatMessage({id:'page_register_mobile'})} onChange={handleChangeField} />
                                            }></GridLayout>

                                          <GridLayout verticalSpace={16} template='6+6'
                                            left={
                                              <EmailField fullWidth name="email" error={handleError('email')!==null} helperText={handleError('email')??undefined}  label={intl.formatMessage({id:'page_register_email'})} onChange={handleChangeField} maxLength={80} />
                                            }
                                            right={
                                              <></>
                                            }></GridLayout>

                                          <Box paddingTop={16}>
                                            <Text3 medium>{intl.formatMessage({id:'page_register_tax_residence'})}</Text3>
                                          </Box>

                                          <GridLayout verticalSpace={16} template='6+6'
                                            left={
                                              <Select disabled={notUserButClient}  fullWidth label={intl.formatMessage({id:'page_register_country'})} name="taxAddressCountry" error={handleError('taxAddressCountry')!==null} helperText={handleError('taxAddressCountry')??undefined} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('taxAddressCountry', val)}></Select>
                                            }
                                            right={
                                              <TextField disabled={notUserButClient} fullWidth name="taxAddressZipcode" error={handleError('taxAddressZipcode')!==null} helperText={handleError('taxAddressZipcode')??undefined} label={intl.formatMessage({id:'page_register_zipcode'})} maxLength={ formData.taxAddressZipcode === process.env.REACT_APP_ID_PAIS_ESPANA ? 5 : 10} onChange={handleChangeField} onBlur={handleZipcode} />
                                            }></GridLayout>

                                          <GridLayout verticalSpace={16} template='6+6'
                                            left={
                                              <Select fullWidth disabled={notUserButClient || disabledTaxAddressProvince}  value={formData.taxAddressProvince} label={intl.formatMessage({id:'page_register_province'})} name='taxAddressProvince' error={handleError('taxAddressProvince')!==null} helperText={handleError('taxAddressProvince')??undefined} options={provinceOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('taxAddressProvince', val)}></Select>
                                            }
                                            right={
                                              <TextField disabled={notUserButClient} fullWidth value={formData.taxAddressLocality} label={intl.formatMessage({id:'page_register_locality'})} name='taxAddressLocality' error={handleError('taxAddressLocality')!==null} helperText={handleError('taxAddressLocality')??undefined} maxLength={70}  onChange={handleChangeField} ></TextField>
                                            }></GridLayout>

                                          <GridLayout verticalSpace={16} template='6+6'
                                            left={
                                              <Select disabled={notUserButClient} fullWidth label={intl.formatMessage({id:'page_register_via'})} name="taxAddressVia" options={viaOptions.map(item => ({text:item.descripcion, value:item.valor}))} error={handleError('taxAddressVia')!==null} helperText={handleError('taxAddressVia')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('taxAddressVia', val)}></Select>
                                            }
                                            right={
                                              <TextField disabled={notUserButClient} fullWidth name="taxAddressNameVia" error={handleError('taxAddressNameVia')!==null} helperText={handleError('taxAddressNameVia')??undefined} label={intl.formatMessage({id:'page_register_name_via'})} onChange={handleChangeField} />
                                            }></GridLayout>

                                          <GridLayout verticalSpace={16} template='6+6'
                                            left={
                                              <GridLayout verticalSpace={16} template='8+4'
                                                left={
                                                  <IntegerField disabled={notUserButClient} fullWidth label={intl.formatMessage({id:'page_register_number'})} name="taxAddressNumber"  error={handleError('taxAddressNumber')!==null} helperText={handleError('taxAddressNumber')??undefined} maxLength={5} onChange={handleChangeField}></IntegerField>
                                                } 
                                                right={
                                                  <Tooltip
                                                    targetLabel="help text"
                                                    target={<IconInformationUserRegular size={18} color={skinVars.colors.brand}/>}
                                                    position="top"
                                                    description={intl.formatMessage({id:'page_register_number_tooltip'})}
                                                  />
                                              }/>
                                            }
                                            right={
                                              <TextField disabled={notUserButClient} optional fullWidth name="taxAddressFloor" label={intl.formatMessage({id:'page_register_floor'})}  error={handleError('taxAddressFloor')!==null} helperText={handleError('taxAddressFloor')??undefined} onChange={handleChangeField} maxLength={10}  />
                                            }></GridLayout>


                                          {!notUserButClient &&
                                            <Stack space={16}>
                                              <Box paddingTop={16}>
                                                <Text3 medium>{intl.formatMessage({id:'page_register_correspondence_address'})}</Text3>
                                              </Box>

                                              <Checkbox onChange={(val:any)=> handleChangeAnyField('taxAddressEqual', val)} name="taxAddressEqual" render={({controlElement, labelId}:any) => (
                                                      <Inline alignItems="center" space={16}>
                                                        {controlElement}
                                                        <Text1 regular>{intl.formatMessage({id:'page_register_tax_address_equal'})}</Text1>
                                                      </Inline>
                                              )}></Checkbox>

                                              {!formData.taxAddressEqual &&
                                                <Stack space={16}>

                                                  <GridLayout verticalSpace={16} template='6+6'
                                                    left={
                                                      <Select fullWidth label={intl.formatMessage({id:'page_register_country'})} name="correspondenceAddressCountry" onChangeValue={(val:any)=> handleChangeAnyField('correspondenceAddressCountry', val)} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
                                                    }
                                                    right={<TextField fullWidth name="correspondenceAddressZipcode" error={handleError('correspondenceAddressZipcode')!==null} helperText={handleError('correspondenceAddressZipcode')??undefined} label={intl.formatMessage({id:'page_register_zipcode'})} maxLength={formData.correspondenceAddressZipcode === process.env.REACT_APP_ID_PAIS_ESPANA ? 5 : 10} onChange={handleChangeField} onBlur={handleZipcode} />
                                                    }></GridLayout>


                                                  <GridLayout verticalSpace={16} template='6+6'
                                                    left={
                                                      <Select fullWidth disabled={disabledCorrespondenceAddressProvince} value={formData.correspondenceAddressProvince} label={intl.formatMessage({id:'page_register_province'})} name='correspondenceAddressProvince' error={handleError('correspondenceAddressProvince')!==null} helperText={handleError('correspondenceAddressProvince')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('correspondenceAddressProvince', val)} options={provinceOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
                                                    }
                                                    right={
                                                      <TextField fullWidth value={formData.correspondenceAddressLocality} label={intl.formatMessage({id:'page_register_locality'})} name='correspondenceAddressLocality' error={handleError('correspondenceAddressLocality')!==null} helperText={handleError('correspondenceAddressLocality')??undefined} onChange={handleChangeField} maxLength={70}></TextField>
                                                    }></GridLayout>


                                                  <GridLayout verticalSpace={16} template='6+6'
                                                    left={
                                                      <Select fullWidth label={intl.formatMessage({id:'page_register_via'})} name="correspondenceAddressVia" error={handleError('correspondenceAddressVia')!==null} helperText={handleError('correspondenceAddressVia')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('correspondenceAddressVia', val)} options={viaOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
                                                    }
                                                    right={
                                                      <TextField fullWidth name="correspondenceAddressNameVia" error={handleError('correspondenceAddressNameVia')!==null} helperText={handleError('correspondenceAddressNameVia')??undefined}  label={intl.formatMessage({id:'page_register_name_via'})} onChange={handleChangeField} />
                                                    }></GridLayout>


                                                    <GridLayout verticalSpace={16} template='6+6'
                                                      left={
                                                        <GridLayout verticalSpace={16} template='8+4' 
                                                      left={
                                                    <       IntegerField fullWidth label={intl.formatMessage({id:'page_register_number'})} name="correspondenceAddressNumber" error={handleError('correspondenceAddressNumber')!==null} helperText={handleError('correspondenceAddressNumber')??undefined}  maxLength={5} onChange={handleChangeField}></IntegerField>
                                                                } 
                                                        right={
                                                    <Tooltip
                                                      targetLabel="help text"
                                                      target={<IconInformationUserRegular size={18} color={skinVars.colors.brand}/>}
                                                      position="top"
                                                      description={intl.formatMessage({id:'page_register_number_tooltip'})}
                                                    />
                                                      }/>
                                                        
                                                      }
                                                      right={
                                                        <TextField optional fullWidth name="correspondenceAddressFloor" error={handleError('correspondenceAddressFloor')!==null} helperText={handleError('correspondenceAddressFloor')??undefined}  label={intl.formatMessage({id:'page_register_floor'})} onChange={handleChangeField} maxLength={10}  />
                                                      }></GridLayout>
                                                  </Stack>
                                                }
                                              </Stack>
                                          }

                                          <Box paddingTop={16}>
                                            <ButtonLayout align='left'>
                                              <ButtonPrimary disabled={isSubmitDisabled()} submit>{intl.formatMessage({id:'page_register_actions_continue_to_customer_knowledge'})}</ButtonPrimary>
                                            </ButtonLayout>
                                          </Box>

                                        </Stack>
                                      </>
                                    }
                                  </Stack>
                                </Form>
                                </Box>
                              </Boxed>
                            }
                            {subStep0=== 1 &&
                              <Boxed>
                                <Box padding={32}>
                                  <Form autoJump onSubmit={handleSubmit} initialValues={formData}>
                                    <Stack space={32}>
                                      <Inline space={'between'} alignItems='center'>
                                        <Inline space={8} alignItems='center'>
                                          <Text4 medium color={dataCardStep===2 ? skinVars.colors.buttonPrimaryBackground : skinVars.colors.neutralMedium}>{intl.formatMessage({id:'page_register_customer_knowledge'})} </Text4>
                                          {dataCardStep>2 &&
                                            <IconCheckedRegular size={24} color={skinVars.colors.buttonPrimaryBackground}></IconCheckedRegular>
                                          }
                                        </Inline>
                                        {dataCardStep>2 &&
                                          <ButtonLink onPress={()=>editDataCard(3)}>{intl.formatMessage({id:'page_register_actions_edit'})}</ButtonLink>
                                        }
                                      </Inline>
                                      {dataCardStep===2 &&
                                        <>
                                        <Stack space={16}>
                                          <GridLayout verticalSpace={16} template='6+6'
                                            left={
                                              <Select fullWidth label={intl.formatMessage({id:'page_register_employment_situation'})} name="employmentSituation" error={handleError('employmentSituation')!==null} helperText={handleError('employmentSituation')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('employmentSituation', val)} options={employmentSituationOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
                                            }
                                            right={
                                              <Stack space={16}>
                                                <Select fullWidth value={formData.activitySector} label={intl.formatMessage({id:'page_register_activity_sector'})} name="activitySector" error={handleError('activitySector')!==null} helperText={handleError('activitySector')??undefined} disabled={activitySectorOptions.length===0}  onChangeValue={(val:any)=> handleChangeAnyField('activitySector', val)} options={activitySectorOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
                                                {visibleOtherActivitySector &&
                                                  <TextField fullWidth name="otherActivitySector" error={handleError('otherActivitySector')!==null} helperText={handleError('otherActivitySector')??undefined}  label={intl.formatMessage({id:'page_register_other_activity_sector'})} onChange={handleChangeField} />
                                                }
                                              </Stack>
                                            }></GridLayout>

                                          <Box paddingY={8}>
                                            <Stack space={16}>
                                              <Switch onChange={(val:any)=> handleChangeAnyField('telefonicaGroupEmployee', val)} name="telefonicaGroupEmployee"  render={({controlElement}) => (
                                                  <Inline alignItems="center" space="between">
                                                    <Text2 medium >
                                                      {intl.formatMessage({id:'page_register_telefonica_group_employee'})}
                                                    </Text2>
                                                    {controlElement}
                                                  </Inline>
                                                )}></Switch>


                                                <Switch onChange={(val:any)=> handleChangeAnyField('usTaxIdentificationNumber', val)} name="usTaxIdentificationNumber" render={({controlElement}) => (
                                                    <Inline alignItems="center" space="between">
                                                      <Text2 medium >
                                                        {intl.formatMessage({id:'page_register_us_tax_identification_number'})}
                                                      </Text2>
                                                      {controlElement}
                                                    </Inline>
                                                )}></Switch>


                                                <Switch onChange={(val:any)=> handleChangeAnyField('personPublicResponsibility', val)} name="personPublicResponsibility" render={({controlElement}) => (
                                                    <Inline alignItems="center" space="between">
                                                      <Inline alignItems="center" space={8}>
                                                        <Text2 medium >
                                                          {intl.formatMessage({id:'page_register_person_public_responsibility'})}
                                                        </Text2>
                                                        <Tooltip
                                                              targetLabel="help text"
                                                              target={<IconInformationUserRegular />}
                                                              position="top"
                                                              description={intl.formatMessage({id:'page_register_person_public_responsibility_help_text'})}
                                                            />
                                                      </Inline>
                                                      {controlElement}
                                                    </Inline>
                                                )}></Switch>

                                                {formData.personPublicResponsibility=== true &&
                                                  <>
                                                    <GridLayout verticalSpace={16} template='6+6'
                                                  left={
                                                    <TextField fullWidth name="personPublicResponsibilityPosition" error={handleError('personPublicResponsibilityPosition')!==null} helperText={handleError('personPublicResponsibilityPosition')??undefined}  label={intl.formatMessage({id:'page_register_person_public_responsibility_position'})} onChange={handleChangeField} />
                                                  }
                                                  right={
                                                    <TextField fullWidth name="personPublicResponsibilityOrganism" error={handleError('personPublicResponsibilityOrganism')!==null} helperText={handleError('personPublicResponsibilityOrganism')??undefined} label={intl.formatMessage({id:'page_register_person_public_responsibility_organism'})} onChange={handleChangeField} />
                                                  }></GridLayout>
                                                  </>
                                                }


                                                <Switch onChange={(val:any)=> handleChangeAnyField('familyPublicResponsibilityOrganism', val)} name="familyPublicResponsibilityOrganism" render={({controlElement}) => (
                                                  <Inline alignItems="center" space="between">
                                                  <Inline alignItems="center" space={8}>
                                                    <Text2 medium >
                                                        {intl.formatMessage({id:'page_register_family_public_responsibility_organism'})}
                                                    </Text2>
                                                    <Tooltip
                                                          targetLabel="help text"
                                                          target={<IconInformationUserRegular />}
                                                          position="top"
                                                          description={intl.formatMessage({id:'page_register_family_public_responsibility_organism_help_text'})}
                                                        />
                                                  </Inline>
                                                  {controlElement}
                                                </Inline>
                                                )}></Switch>


                                                <Switch onChange={(val:any)=> handleChangeAnyField('relatedPublicResponsibilityOrganism', val)} name="relatedPublicResponsibilityOrganism" render={({controlElement}) => (
                                                  <Inline alignItems="center" space="between">
                                                  <Inline alignItems="center" space={8}>
                                                    <Text2 medium >
                                                      {intl.formatMessage({id:'page_register_related_public_responsibility_organism'})}
                                                    </Text2>
                                                    <Tooltip
                                                          targetLabel="help text"
                                                          target={<IconInformationUserRegular />}
                                                          position="top"
                                                          description={intl.formatMessage({id:'page_register_related_public_responsibility_organism_help_text'})}
                                                        />
                                                  </Inline>
                                                  {controlElement}
                                                </Inline>
                                                )}></Switch>

                                                <Switch onChange={(val:any)=> handleChangeAnyField('ongPositionLast2Years', val)} name="ongPositionLast2Years" render={({controlElement}) => (
                                                  <Inline alignItems="center" space="between">
                                                  <Inline alignItems="center" space={8}>
                                                    <Text2 medium >
                                                      {intl.formatMessage({id:'page_register_question_ong'})}
                                                    </Text2>
                                                  </Inline>
                                                  {controlElement}
                                                </Inline>
                                                )}></Switch>
                                              </Stack>
                                            </Box>

                                            <Box paddingTop={16}>
                                              <Stack space={16}>
                                                <Checkbox onChange={(val:any)=> handleChangeAnyField('informationTrueVerifiable', val)} name="informationTrueVerifiable" render={({controlElement, labelId}:any) => (
                                                    <Inline alignItems="center" space={16}>
                                                      {controlElement}
                                                      <Text3 regular id={labelId}>
                                                        {intl.formatMessage({id:'page_register_information_true_verifiable'})}
                                                      </Text3>
                                                    </Inline>
                                                )}></Checkbox>

                                                <Checkbox onChange={(val:any)=> handleChangeAnyField('legalOriginIncome', val)} name="legalOriginIncome" render={({controlElement, labelId}:any) => (
                                                    <Inline alignItems="center" space={16}>
                                                      {controlElement}
                                                      <Text3 regular id={labelId}>
                                                        {intl.formatMessage({id:'page_register_legal_origin_income'})}
                                                      </Text3>
                                                    </Inline>
                                                  )}></Checkbox>
                                                  <div className='html-content contain-politics-text' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_register_politics_text'})}}></div>

                                                  <Checkbox onChange={(val:any)=> handleChangeAnyField('acceptPrivacyPolicy', val)} name="acceptPrivacyPolicy" render={({controlElement, labelId}:any) => (
                                                      <Inline alignItems="center" space={16}>
                                                        {controlElement}
                                                        <Text3 regular id={labelId}>
                                                          {intl.formatMessage({id:'page_register_accept_privacy_policy'},{privacy_policy:<TextLink onPress={()=>{ showAlert('_privacy_policy_') }}>{intl.formatMessage({id:'page_register_accept_privacy_policy_link_text'})}</TextLink>})}
                                                        </Text3>
                                                      </Inline>
                                                  )}></Checkbox>
                                                </Stack>
                                            </Box>

                                            <Box paddingTop={16}>
                                              <ButtonLayout align='left'>
                                                <ButtonPrimary disabled={isSubmitDisabled()} submit>{intl.formatMessage({id:'page_register_actions_continue_keys_generation'})}</ButtonPrimary>
                                              </ButtonLayout>
                                            </Box>
                                          </Stack>
                                        </>
                                      }
                                    </Stack>
                                  </Form>
                                </Box>
                              </Boxed>
                            }
                            </Stack>
                          </ResponsiveLayout>
                        }

                        {step===1 &&
                          <ResponsiveLayout className='form'>
                              <Boxed>
                                <Box padding={32}>
                                    <Form autoJump onSubmit={handleSubmit} initialValues={formData} className={loadingForm ? 'loading' : ''}>
                                      <Stack space={8}>
                                          <Text4 medium color={skinVars.colors.buttonPrimaryBackground}>{intl.formatMessage({id:'page_register_access_password_title'})} </Text4>
                                          <Text3 regular color={skinVars.colors.neutralHigh}>{intl.formatMessage({id:'page_register_access_password_description'})} </Text3>

                                          <Box paddingTop={16}>
                                            <GridLayout verticalSpace={16} template='6+6'
                                              left={
                                                <Stack space={8}>
                                                  <PasswordField fullWidth name="password" error={handleError('password')!==null} helperText={handleError('password')??undefined} label={intl.formatMessage({id:'page_register_access_password'})} onChange={handleChangeField} />
                                                  <Text1 regular color={skinVars.colors.neutralMedium}>{intl.formatMessage({id:'page_register_access_password_help_text'})}</Text1>
                                                </Stack>
                                              }
                                              right={
                                                <PasswordField fullWidth name="repeatPassword" error={handleError('repeatPassword')!==null} helperText={handleError('repeatPassword')??undefined} label={intl.formatMessage({id:'page_register_access_password_confirm'})} onChange={handleChangeField} />
                                              }></GridLayout>
                                          </Box>


                                        <Box paddingY={32}>
                                          <Stack space={8}>
                                            <Text4 medium color={skinVars.colors.buttonPrimaryBackground}>{intl.formatMessage({id:'page_register_operations_key_title'})} </Text4>
                                            <Text3 regular color={skinVars.colors.neutralHigh}>{intl.formatMessage({id:'page_register_operations_key_description'})} </Text3>

                                            <Box paddingTop={16}>
                                              <GridLayout verticalSpace={16} template='6+6'
                                                left={

                                                  <Stack space={8}>
                                                  <PasswordField fullWidth name="operationsKey" error={handleError('operationsKey')!==null} helperText={handleError('operationsKey')??undefined}  label={intl.formatMessage({id:'page_register_operations_key'})} onChange={handleChangeField} />
                                                  <Text1 regular color={skinVars.colors.neutralMedium}>{intl.formatMessage({id:'page_register_operations_key_help_text'})}</Text1>
                                                </Stack>
                                                }
                                                right={
                                                  <PasswordField fullWidth name="repeatOperationsKey"  error={handleError('repeatOperationsKey')!==null} helperText={handleError('repeatOperationsKey')??undefined} label={intl.formatMessage({id:'page_register_operations_key_confirm'})} onChange={handleChangeField} />
                                                }></GridLayout>
                                              </Box>
                                          </Stack>
                                        </Box>
                                        <ButtonLayout align='left'>
                                          <ButtonSecondary onPress={handleReturnPersonalData}><Text3 regular color="currentColor">{intl.formatMessage({id:'page_register_actions_return_personal_data'})}</Text3></ButtonSecondary>
                                          <ButtonPrimary disabled={isSubmitDisabled()} submit><Text3 regular color="currentColor">{intl.formatMessage({id:'page_register_actions_continue'})}</Text3></ButtonPrimary>
                                        </ButtonLayout>
                                      </Stack>
                                    </Form>
                                </Box>
                              </Boxed>
                          </ResponsiveLayout>
                        }
                        {step===2 &&
                          <ResponsiveLayout className='form large'>
                            <Stack space={32}>
                                <Stack space={16}>
                                  <Text4 medium>{intl.formatMessage({id:'page_register_signature_title'})}</Text4>
                                  <Text4 medium>{intl.formatMessage({id:'page_register_signature_description'})}</Text4>
                                </Stack>
                                <Form autoJump onSubmit={handleSubmit} initialValues={formDataSignature} className={loadingForm ? 'loading' : ''}>
                                  <ResponsiveLayout className='double-cards'>
                                  <RadioGroup name='sign_contract' onChange={(val:any)=> handleChangeAnyField('sign_contract', val)}>
                                    <GridLayout verticalSpace={16} template='6+6'
                                      left={
                                          <DisplayDataCard icon={<IconComputerRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconComputerRegular>}
                                            title={intl.formatMessage({id:'page_register_signature_digitally_title'})}
                                            description={intl.formatMessage({id:'page_register_signature_digitally_description'})}
                                            extra={
                                              <Box paddingTop={32}>
                                                    <RadioButton id='digitally' value="digitally">
                                                      <Text2 regular>{intl.formatMessage({id:'page_register_signature_select_option'})}</Text2>
                                                    </RadioButton>
                                                </Box>
                                            }></DisplayDataCard>
                                      }
                                      right={
                                          <DisplayDataCard icon={<IconDocumentOtherRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconDocumentOtherRegular>}
                                            title={intl.formatMessage({id:'page_register_signature_print_documentation_title'})}
                                            description={intl.formatMessage({id:'page_register_signature_print_documentation_description'})}
                                            extra={
                                              <Box paddingTop={32}>
                                                    <RadioButton id='print_documentation' value="print_documentation">
                                                      <Text2 regular>{intl.formatMessage({id:'page_register_signature_select_option'})}</Text2>
                                                    </RadioButton>
                                                </Box>
                                            }></DisplayDataCard>
                                      }></GridLayout>
                                    </RadioGroup>

                                    <Box paddingY={32}>
                                      <ButtonLayout align="center" link={<></>}>
                                        <ButtonPrimary submit><Text3 regular color="currentColor">{intl.formatMessage({id:'page_register_actions_continue'})}</Text3></ButtonPrimary>
                                      </ButtonLayout>
                                    </Box>
                                  </ResponsiveLayout>
                              </Form>
                            </Stack>
                          </ResponsiveLayout>

                        }
                        {step===3 &&
                          <>
                            <ResponsiveLayout>
                              {urlSignature &&
                                <iframe id='iframeSignaturit'
                                  src={urlSignature}
                                  onLoad={onLoadedIframeSignaturit}
                                  style={{ width: '1px', minWidth: '100%', scrollBehavior:'auto'}}
                                />
                              }
                            </ResponsiveLayout>
                          </>
                        }
                        {step===4 &&
                          <ResponsiveLayout className='form extra-large'>
                            <Stack space={32}>
                              <ResponsiveLayout className='form'>
                                <Stack space={16}>
                                  <Text4 medium>{intl.formatMessage({id:'page_register_finished_title'})}</Text4>
                                  <Text4 medium>{intl.formatMessage({id:'page_register_finished_description'})}</Text4>
                                </Stack>
                                </ResponsiveLayout>
                                  {formDataSignature.sign_contract==='digitally' ?
                                    <Grid gap={16} columns={screenSize.isDesktopOrBigger ? 3 : 1}>
                                      {formDataSignature.product && formDataSignature.product.length>0 &&
                                        <DisplayDataCard
                                          isInverse={true}
                                          icon={<IconProcessLoadingRegular size={40} color={skinVars.colors.background}></IconProcessLoadingRegular>}
                                          title={intl.formatMessage({id:'page_register_finished_continue_contract_title'})}
                                          buttonLink={<ButtonLink onPress={continueContract}>{intl.formatMessage({id:'page_register_finished_continue_contract_action'})}</ButtonLink>}></DisplayDataCard>
                                      }
                                      <DisplayDataCard
                                          icon={<IconPenRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconPenRegular>}
                                          title={intl.formatMessage({id:'page_register_finished_signed_document_title'})}
                                          buttonLink={<ButtonLink onPress={getSignedDocument}>{intl.formatMessage({id:'page_register_finished_signed_document_action'})}</ButtonLink>}></DisplayDataCard>
                                      <DisplayDataCard
                                          icon={<IconDocumentsRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconDocumentsRegular>}
                                          title={intl.formatMessage({id:'page_register_finished_attachments_title'})}
                                          buttonLink={<ButtonLink onPress={getAttachmentSignedDocument}>{intl.formatMessage({id:'page_register_finished_attachments_action'})}</ButtonLink>}></DisplayDataCard>
                                      {!(formDataSignature.product && formDataSignature.product.length>0) &&
                                          <DisplayDataCard
                                            icon={<IconUserAccountRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconUserAccountRegular>}
                                            title={intl.formatMessage({id:'page_register_finished_private_area_title'})}
                                            buttonLink={<ButtonLink onPress={()=>{router.push(getUrlNavigation(LoginPage)); trackEvent('acceso-registro-login');  }}>{intl.formatMessage({id:'page_register_finished_private_area_action'})}</ButtonLink>}></DisplayDataCard>
                                      }
                                      </Grid>
                                    :
                                    <Grid gap={16} columns={screenSize.isDesktopOrBigger ? 2 : 1}>
                                        {formDataSignature.product && formDataSignature.product.length>0 &&
                                          <DisplayDataCard
                                            isInverse={true}
                                            icon={<IconProcessLoadingRegular size={40} color={skinVars.colors.background}></IconProcessLoadingRegular>}
                                            title={intl.formatMessage({id:'page_register_finished_continue_contract_title'})}
                                            buttonLink={<ButtonLink onPress={continueContract}>{intl.formatMessage({id:'page_register_finished_continue_contract_action'})}</ButtonLink>}></DisplayDataCard>
                                        }
                                        <DisplayDataCard
                                            icon={<IconPenRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconPenRegular>}
                                            title={intl.formatMessage({id:'page_register_finished_document_title'})}
                                            buttonLink={<ButtonLink onPress={getContractDocument}>{intl.formatMessage({id:'page_register_finished_document_action'})}</ButtonLink>}></DisplayDataCard>

                                        {!(formDataSignature.product && formDataSignature.product.length>0) &&
                                          <DisplayDataCard
                                            icon={<IconUserAccountRegular size={40} color={skinVars.colors.buttonPrimaryBackground}></IconUserAccountRegular>}
                                            title={intl.formatMessage({id:'page_register_finished_private_area_title'})}
                                            buttonLink={<ButtonLink onPress={()=>{router.push(getUrlNavigation(LoginPage)); trackEvent('acceso-registro-login'); }}>{intl.formatMessage({id:'page_register_finished_private_area_action'})}</ButtonLink>}></DisplayDataCard>
                                        }
                                    </Grid>
                                  }
                            </Stack>
                          </ResponsiveLayout>
                        }
                  </div>
                </ResponsiveLayout>
              </>
            }
          </ResponsiveLayout>
      </LayoutPublic>
  );
};

export default RegisterPage;
